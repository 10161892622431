.mask {
    width: 100%;
    height: 100vh;
    position: relative;

}

.into-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;

}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;

}

.hbody {
    height: 100%;
    width: 100%;
}

.hbody .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.hbody .content h1 {
    font-size: 2rem;
    padding: 0.6rem 0 1.5rem;
}

.hbody .content p {
    font-size: 1.4rem;
    font-weight: 200%;
    text-transform: uppercase;
}

.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 576px) {
    .hbody .content h1 {
        font-size: 1.5rem;
        padding: 0.6rem 0 1.5rem;
        height: 100%;
        width: 100%;
        

    }
    .hbody .content H4 {
        font-size: calc(1.0rem + .3vw);
    }
    .hbody .content p {
        font-size: 1.0rem;
        display: auto;


    }
    @media screen and (min-width: 576px) {
        .hbody .content h1 {
            font-size: 1rem;
            padding: 0.6rem 0 1.5rem;
            height: 100%;
            width: 100%;
    
        }
    
        .hbody .content p {
            font-size: 1.4rem;
            display: auto;
    
    
        }
    }
}