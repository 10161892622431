.signInOption{
    display: flex;
    text-align: center;
    
}
.signForm{
    background-color: rgb(12, 5, 5);
    padding-top: 100px;
    padding-bottom: 10px;
    padding-block-start: 100px;
    
}