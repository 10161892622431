.pagebody-img{
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;

}
.pagebody-img ::before {
     content: "";
     background: url(https://images.unsplash.com/photo-1596524430615-b46475ddff6e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y29udGFjdCUyMHVzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60);
     background-size: cover;
     background-position: center top;
     height: 100%;
     width: 100%;
     position: absolute;
     top: 0;
     left: 0;
     z-index: -1;
}
.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pagebody-img h1{
    font-size: 1.4rem;
    text-align: center;
    
}
@media screen and(max-width:640px) {
    .body-img h1{
        font-size: 1rem;
    }
}