.textSytle{
    background: #efcece;
}
.cardImg{
    width: 10%;
    height: 10%;
}
.benefits{
    background-color: #212529;
    width: 90vw;
    margin: 3rem;
}