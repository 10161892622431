.formInput{
    display: flex;
    flex-direction: column;
    width: 280px;
}

input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
    height: 90%;
    width: 100%;
}


label{
    font-size: 12px;
    color: gray;
}

span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
}
.form input form textarea {
    margin-bottom: 1rem;
    padding: 10px,18px;
    font-size: 1.2rem;
    background-color: #f9fafb; 
    height: 90%;
    width: 100%;

} 
